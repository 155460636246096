import type { FC } from 'react';
import { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Feedback from 'components/commercetools-ui/organisms/account/account-atoms/feedback';
import type { FieldErrors } from 'components/commercetools-ui/organisms/account/types';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { useGoogleSettingsContext } from 'providers/google-settings';

interface ReCaptchaProps {
    innerRef: React.RefObject<ReCAPTCHA>;
    setReadyState: (ready: boolean) => void;
    errors?: FieldErrors;
}

export type ReCaptchaRef = ReCAPTCHA;

const ReCaptcha: FC<ReCaptchaProps> = ({ innerRef, setReadyState, errors }) => {
    const { formatMessage: formatValidationMessage } = useFormat({ name: 'validation' });

    const { reCaptchaEnabled, reCaptchaSiteKey, reCaptchaTheme, reCaptchaPosition, reCaptchaHideBadge, reCaptchaHiddenBadgeText } =
        useGoogleSettingsContext();

    const reCaptchaContainerClassNames = useClassNames([reCaptchaHideBadge ? 'hidden' : '', 'z-10']);

    useEffect(() => {
        if (!reCaptchaSiteKey || !reCaptchaEnabled) {
            setReadyState(true);
        }
    }, [reCaptchaSiteKey, reCaptchaEnabled]);

    if (!reCaptchaSiteKey || !reCaptchaEnabled) {
        return null;
    }

    return (
        <>
            <div className={reCaptchaContainerClassNames}>
                <ReCAPTCHA
                    ref={innerRef}
                    size="invisible"
                    sitekey={reCaptchaSiteKey}
                    theme={reCaptchaTheme || 'light'}
                    badge={reCaptchaPosition || 'bottomright'}
                    asyncScriptOnLoad={() => setReadyState(true)}
                />
            </div>
            {errors?.reCaptchaToken?.message && (
                <Feedback
                    success=""
                    error={formatValidationMessage({
                        id: errors.reCaptchaToken.message,
                        defaultMessage: 'Could not verify reCAPTCHA'
                    })}
                />
            )}
            {reCaptchaHideBadge && <Markdown className="mb-8 text-center text-md" markdown={reCaptchaHiddenBadgeText} />}
        </>
    );
};

export default ReCaptcha;
