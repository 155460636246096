import type { FC } from 'react';

export interface ChipProps {
    type: 'BUSINESS' | 'INDIVIDUAL';
}

const Chip: FC<ChipProps> = ({ type }) => {
    const chipBGColor = type === 'INDIVIDUAL' ? 'bg-chip-individual' : 'bg-chip-business';
    const classStyles = `${chipBGColor} rounded-full px-10 py-1 text-sm font-bold text-white`;
    return <span className={classStyles}>{type}</span>;
};

export default Chip;
