import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="62" height="45" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.5 11.5C14.6769 11.5 17.25 8.92688 17.25 5.75C17.25 2.57312 14.6769 0 11.5 0C8.32312 0 5.75 2.57312 5.75 5.75C5.75 8.92688 8.32312 11.5 11.5 11.5ZM11.5 14.375C7.66187 14.375 0 16.3012 0 20.125V23H23V20.125C23 16.3012 15.3381 14.375 11.5 14.375Z"
            fill="#CDCDCD"
        />
        <path
            d="M21.3828 11.6437L26.45 6.54062C26.7375 6.25312 27.0789 6.10938 27.4742 6.10938C27.8695 6.10938 28.2109 6.25312 28.4984 6.54062C28.7859 6.82812 28.9297 7.16953 28.9297 7.56484C28.9297 7.96016 28.7859 8.30156 28.4984 8.58906L22.3891 14.6984C22.1016 14.9859 21.7661 15.1297 21.3828 15.1297C20.9995 15.1297 20.6641 14.9859 20.3766 14.6984L17.3219 11.6437C17.0344 11.3562 16.8906 11.0148 16.8906 10.6195C16.8906 10.2242 17.0344 9.88281 17.3219 9.59531C17.6094 9.30781 17.9448 9.16406 18.3281 9.16406C18.7115 9.16406 19.0469 9.30781 19.3344 9.59531L21.3828 11.6437Z"
            fill="#CDCDCD"
        />
    </svg>
);

export default Icon;
