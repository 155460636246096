import statesData from '../../public/states.json';

export interface States {
    value: string;
    label: string;
}

interface MapStates {
    states?: States[];
    requred: boolean;
}

const mapStates: (targetValue: string, defaultOption: string) => MapStates = (targetValue: string, defaultOption: string) => {
    const filteredCountry = statesData.filter(country => country.countryCode === targetValue);
    const statesEmptyOption: States = {
        value: '',
        label: defaultOption
    };

    const states = filteredCountry.length ? filteredCountry[0].states : [];

    if (states.length && states[0].label !== defaultOption) {
        states.splice(0, 0, statesEmptyOption);
    }

    return {
        states,
        requred: targetValue === 'US' || targetValue === 'CA'
    };
};

export default mapStates;
