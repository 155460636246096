import type { FC } from 'react';
import { useEffect } from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import scrollToError from 'helpers/utils/scrollToError';

type FeedbackProps = {
    error: string;
    success: string;
};

const Feedback: FC<FeedbackProps> = ({ error, success }) => {
    const feedBackElementClassName = useClassNames(['mb-12 text-12', success ? 'text-green-600' : 'text-input-error']);

    useEffect(() => {
        if (error) {
            scrollToError();
        }
    }, [error]);

    if (!error && !success) return <></>;

    return (
        <Typography data-cy="form-error" fontSize={12} className={feedBackElementClassName} data-error={!!error}>
            {error || success}
        </Typography>
    );
};

export default Feedback;
