import type { FC } from 'react';
import { useState } from 'react';
import EyeIcon from 'components/icons/eye';
import EyeSlashIcon from 'components/icons/eye-slash';
import type { InputProps } from '../input';
import Input from '../input';

const PasswordInput: FC<InputProps> = props => {
    const [hidden, setHidden] = useState<boolean>(true);
    const [type, setType] = useState<'text' | 'password'>('password');

    const toggleHidden = (e: React.MouseEvent) => {
        e.preventDefault();
        setHidden(!hidden);
        setType(type === 'password' ? 'text' : 'password');
    };

    const iconsClassName = 'w-18';

    return (
        <div className="relative">
            <Input type={type} hideCheckIcon {...props}>
                <button
                    type="button"
                    className="absolute right-12 top-1/2 z-10 -translate-y-1/2 hover:cursor-pointer"
                    onClick={toggleHidden}
                >
                    {hidden ? <EyeSlashIcon className={iconsClassName} /> : <EyeIcon className={iconsClassName} />}
                </button>
            </Input>
        </div>
    );
};

export default PasswordInput;
