import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.03906 0.414062L5.10156 2.79297C6.33203 1.86328 7.86328 1.125 9.75 1.125C11.9375 1.125 13.7148 2.13672 15 3.33984C16.2852 4.51562 17.1328 5.9375 17.543 6.92188C17.625 7.14062 17.625 7.38672 17.543 7.60547C17.1875 8.48047 16.4492 9.73828 15.3555 10.832L18.2266 13.1016C18.5273 13.3203 18.582 13.7305 18.3359 14.0039C18.1172 14.3047 17.707 14.3594 17.4336 14.1133L1.24609 1.42578C0.945312 1.20703 0.890625 0.796875 1.13672 0.523438C1.35547 0.222656 1.76562 0.167969 2.03906 0.414062ZM6.16797 3.64062L7.42578 4.625C8.05469 4.07812 8.84766 3.75 9.75 3.75C11.6641 3.75 13.25 5.33594 13.25 7.25C13.25 7.85156 13.1133 8.39844 12.8398 8.86328L14.3164 10.0117C15.2734 9.08203 15.9297 8.01562 16.2578 7.25C15.9023 6.42969 15.1641 5.28125 14.0977 4.29688C12.9766 3.25781 11.5273 2.4375 9.75 2.4375C8.35547 2.4375 7.17969 2.92969 6.16797 3.64062ZM11.7734 8.04297C11.8828 7.79688 11.9375 7.52344 11.9375 7.25C11.9375 6.04688 10.9531 5.0625 9.75 5.0625C9.72266 5.0625 9.69531 5.0625 9.69531 5.0625C9.72266 5.22656 9.75 5.36328 9.75 5.5C9.75 5.80078 9.66797 6.04688 9.55859 6.29297L11.7734 8.04297ZM12.0469 11.5977L13.1953 12.5C12.1836 13.0469 11.0352 13.375 9.75 13.375C7.53516 13.375 5.75781 12.3906 4.47266 11.1875C3.1875 9.98438 2.33984 8.5625 1.92969 7.60547C1.84766 7.38672 1.84766 7.14062 1.92969 6.92188C2.20312 6.29297 2.64062 5.5 3.26953 4.67969L4.28125 5.5C3.78906 6.12891 3.43359 6.75781 3.21484 7.25C3.59766 8.07031 4.30859 9.24609 5.375 10.2305C6.49609 11.2695 7.94531 12.0625 9.75 12.0625C10.5703 12.0625 11.3359 11.8984 12.0469 11.5977ZM6.25 7.25H6.22266C6.22266 7.19531 6.25 7.11328 6.25 7.03125L7.78125 8.23438C8.05469 8.80859 8.60156 9.24609 9.25781 9.38281L10.7891 10.6133C10.4609 10.6953 10.1055 10.75 9.75 10.75C7.80859 10.75 6.25 9.19141 6.25 7.25Z"
            fill="black"
        />
    </svg>
);
export default Icon;
