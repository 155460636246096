import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.875 0.125C11.0625 0.125 12.8398 1.13672 14.125 2.33984C15.4102 3.51562 16.2578 4.9375 16.668 5.92188C16.75 6.14062 16.75 6.38672 16.668 6.60547C16.2578 7.5625 15.4102 8.98438 14.125 10.1875C12.8398 11.3906 11.0625 12.375 8.875 12.375C6.66016 12.375 4.88281 11.3906 3.59766 10.1875C2.3125 8.98438 1.46484 7.5625 1.05469 6.60547C0.972656 6.38672 0.972656 6.14062 1.05469 5.92188C1.46484 4.9375 2.3125 3.51562 3.59766 2.33984C4.88281 1.13672 6.66016 0.125 8.875 0.125ZM4.9375 6.25C4.9375 7.67188 5.67578 8.95703 6.90625 9.66797C8.10938 10.3789 9.61328 10.3789 10.8438 9.66797C12.0469 8.95703 12.8125 7.67188 12.8125 6.25C12.8125 4.85547 12.0469 3.57031 10.8438 2.85938C9.61328 2.14844 8.10938 2.14844 6.90625 2.85938C5.67578 3.57031 4.9375 4.85547 4.9375 6.25ZM8.875 4.5C8.875 4.30859 8.82031 4.14453 8.76562 3.95312C8.71094 3.81641 8.82031 3.625 8.98438 3.625C10.0781 3.67969 11.0898 4.44531 11.3906 5.59375C11.7734 6.98828 10.9531 8.41016 9.53125 8.79297C8.13672 9.17578 6.6875 8.32812 6.33203 6.93359C6.27734 6.74219 6.25 6.55078 6.25 6.38672C6.22266 6.22266 6.41406 6.11328 6.55078 6.16797C6.74219 6.22266 6.90625 6.25 7.125 6.25C8.08203 6.25 8.875 5.48438 8.875 4.5Z"
            fill="#414141"
        />
    </svg>
);
export default Icon;
