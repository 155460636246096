import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="62" height="45" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.7778 5.11111V0H0V23H25.5556V5.11111H12.7778ZM5.11111 20.4444H2.55556V17.8889H5.11111V20.4444ZM5.11111 15.3333H2.55556V12.7778H5.11111V15.3333ZM5.11111 10.2222H2.55556V7.66667H5.11111V10.2222ZM5.11111 5.11111H2.55556V2.55556H5.11111V5.11111ZM10.2222 20.4444H7.66667V17.8889H10.2222V20.4444ZM10.2222 15.3333H7.66667V12.7778H10.2222V15.3333ZM10.2222 10.2222H7.66667V7.66667H10.2222V10.2222ZM10.2222 5.11111H7.66667V2.55556H10.2222V5.11111ZM23 20.4444H12.7778V17.8889H15.3333V15.3333H12.7778V12.7778H15.3333V10.2222H12.7778V7.66667H23V20.4444ZM20.4444 10.2222H17.8889V12.7778H20.4444V10.2222ZM20.4444 15.3333H17.8889V17.8889H20.4444V15.3333Z"
            fill="#CDCDCD"
        />
    </svg>
);

export default Icon;
