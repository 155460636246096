import type { FC } from 'react';
import React from 'react';
import Modal from 'components/commercetools-ui/atoms/modal';
import { useBrandSettingsContext } from 'providers/brand-settings';
import Markdown from 'components/commercetools-ui/organisms/markdown';

export interface Props {
    isActivationModalOpen: boolean;
    setIsActivationModalOpen: (val: boolean) => void;
    email: string;
}
const ActivationModal: FC<Props> = ({ isActivationModalOpen, setIsActivationModalOpen, email }) => {
    const { activationModal } = useBrandSettingsContext();
    const content = activationModal.content;
    const markdownContent = content.replace('{{email}}', email);
    return (
        <Modal
            isOpen={isActivationModalOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '560px' } }}
            preventScroll={true}
            closeButton
            onRequestClose={() => setIsActivationModalOpen(false)}
        >
            <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                <p className="text-20 font-bold text-register-note">{activationModal.title}</p>

                <Markdown markdown={markdownContent ?? ''} />
            </div>
        </Modal>
    );
};

export default ActivationModal;
