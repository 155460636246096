import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="62" height="45" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5234 13.124C24.4928 14.4609 25.8728 16.2721 25.8728 18.6871V22.9996H31.6228V18.6871C31.6228 15.5534 26.4909 13.699 22.5234 13.124Z"
            fill="#CDCDCD"
        />
        <path
            d="M11.5 11.5C14.6756 11.5 17.25 8.92564 17.25 5.75C17.25 2.57436 14.6756 0 11.5 0C8.32436 0 5.75 2.57436 5.75 5.75C5.75 8.92564 8.32436 11.5 11.5 11.5Z"
            fill="#CDCDCD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.1248 11.5C23.3016 11.5 25.8748 8.92688 25.8748 5.75C25.8748 2.57312 23.3016 0 20.1248 0C19.4491 0 18.8166 0.14375 18.2129 0.345C19.406 1.82562 20.1248 3.70875 20.1248 5.75C20.1248 7.79125 19.406 9.67438 18.2129 11.155C18.8166 11.3563 19.4491 11.5 20.1248 11.5Z"
            fill="#CDCDCD"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 12.9375C7.66187 12.9375 0 14.8637 0 18.6875V23H23V18.6875C23 14.8637 15.3381 12.9375 11.5 12.9375Z"
            fill="#CDCDCD"
        />
    </svg>
);

export default Icon;
